
@import "~bootstrap/dist/css/bootstrap.css";

@font-face {
  font-family: 'GeosansLight';
  font-style: regular;
  src: url('/assets/font/geosanslight.ttf') format("truetype");
}
@font-face {
  font-family: 'Lora-SemiBold';
  font-style: regular;
  src: url('/assets/font/Lora-SemiBold.ttf') format("truetype");
}
@font-face {
  font-family: 'Lora-Bold';
  font-style: regular;
  src: url('/assets/font/Lora-Bold.ttf') format("truetype");
}

body {
    font-family: GeosansLight !important;
    color: #041e42;
    background-color: #f8f6f2;
    height: 100%;
  margin: 0;
}


.p-left{
	padding-left:10px;
}
.top-mr{
    margin-top: 80px;
}

.rest-info-2{
  font-family: GeosansLight;
    font-size: 20px;
    line-height: 27px;
    text-transform: none;
    color:#666d72;
    letter-spacing: 1.2px;
}
.mb-20{
  margin-bottom:20px;
}
.mt-20{
  margin-top:20px;
}
.mt-90{
  margin-top:125px;
}
.restaurant-info-details{
  margin-left:40px;
}
.pt-20{
  padding-top: 20px;
}
.cc-banner{
  height:80px;
}
.cc-banner .cc-message {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    margin-right: 1em;
    font-family: GeosansLight!important;
    letter-spacing: 1.5px!important;
}
.mt-40{
 margin-top: 40px;
}


@media (max-width: 768px){
  .mainbackground {
      	margin-top: 30px!important;
  }
  .top-mr{
    margin-top: 50px;
}

}


@media (max-width: 991.98px) {


}

